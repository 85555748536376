

@font-face {
    font-family: 'YSD';
    src: url('./fonts/YandexSansDisplay-Light.eot');
    src: local('./fonts/Yandex Sans Display Light'), local('YandexSansDisplay-Light'),
      url('./fonts/YandexSansDisplay-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/YandexSansDisplay-Light.woff') format('woff'),
      url('./fonts/YandexSansDisplay-Light.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }